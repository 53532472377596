<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="600px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        Informe o % de ajuste desejado
        <v-spacer/>
        <v-btn icon @click="value = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field prefix="%"
                  :rules="[
                      this.$validators.number.required,
                      v => this.$validators.number.greaterThanOrEquals(v, 0)
                  ]"
                  v-model="percentAjuste" type="number"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="enviar" :disabled="!valid">Aplicar percentual</v-btn>
        <v-btn outlined color="primary" @click="value = false">fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "InformaPercentualAjusteDialog",
  data () {
    return {
      valid: true,
      value: false,
      percentAjuste: 0,
    }
  },
  computed: {
  },
  methods: {
    enviar() {
      this.$emit('emitPercentualAjuste',this.percentAjuste);
    },
    open() {
      this.value = true;
    },
    close() {
      this.value = false;
    },
  }
}
</script>

<style scoped>

</style>