<template>
  <div>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.cursos="{item}">
        <p class="ma-0">{{ item.cursos.map(d => d.descricao_idCurso+' - '+d.qtdDias+' dia(s)').join(', ') }}</p>
      </template>
      <template v-slot:item.valMatricula="{item}">
        <p class="ma-0">R$ {{item.valMatricula}}</p>
      </template>
      <template v-slot:item.valDesconto="{item}">
        <p class="ma-0">% {{item.valDesconto}}</p>
      </template>
      <template v-slot:item.valComDesconto="{item}">
        <p class="ma-0">R$ {{valAjustadoComDesconto(item.valMatricula, valorAjuste, item.valDesconto)}}</p>
      </template>
      <template v-slot:item.valAjustadoMatricula="{item}">
        <p class="ma-0">R$ {{valAjustado(item.valMatricula, valorAjuste)}}</p>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-list dense class="pa-0">
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-list-item-title v-text="item.nomeIdCliente"/>
              <v-list-item-subtitle>
                {{ item.cursos.map(d => d.descricao_idCurso).join(', ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip color="primary" class="mr-2" input-value="false" small>R$ {{item.valComDesconto}}</v-chip>
            </v-list-item-action-text>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAll} from '../../../api/matriculas';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "MatriculaAjusteMensalidadeDataTable",
  components: {},
  props: {
    searchValues: {
      type: Object,
    },
    valorAjuste: {
        type: Number 
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
          width: 100
        },
        {
          text: 'Cliente',
          value: 'nomeIdCliente',
          align: 'start'
        },
        {
          text: 'Local',
          value: 'descricaoIdLocal'
        },  
        {
          text: 'Curso(s)',
          value: 'cursos'
        }, 
        {
          text: 'Valor',
          value: 'valMatricula'
        },
        {
          text: 'Valor Ajustado',
          value: 'valAjustadoMatricula'
        },        
        {
          text: 'Desconto',
          value: 'valDesconto'
        },
        {
          text: 'Valor Com Desconto',
          value: 'valComDesconto'
        }
      ],
    }
  },
  computed: {
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    valAjustado(valMatricula, percAjuste) {
      return Math.ceil((valMatricula * percAjuste/100) + valMatricula);
    },
    valAjustadoComDesconto(valMatricula, percAjuste, desconto) {
      let valAjustado = this.valAjustado(valMatricula, percAjuste);
      return  (valAjustado - (valAjustado * desconto / 100)).toFixed(2);
    },    
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    //this.pageOptions.page = 1;
    //this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 132px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>