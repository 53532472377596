<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions:extras>
      {{"Ajuste aplicado: " + valorAjuste + "%"}}
      <v-btn color="primary"
             @click="informaPercentualAjuste"
             :block="$vuetify.breakpoint.mobile" :outlined="$vuetify.breakpoint.mobile" :width="'150px'"
             :text="!$vuetify.breakpoint.mobile"> Simular Ajuste
      </v-btn>
      <v-btn color="primary"
             @click="desejaMesmoEfetivarOsValores"
             :block="$vuetify.breakpoint.mobile" :outlined="$vuetify.breakpoint.mobile" :width="'150px'"
             :text="!$vuetify.breakpoint.mobile"> Efetivar Ajuste
      </v-btn>
    </template>
    <informa-percentual-ajuste-dialog ref="informaPercentualAjusteDialog" 
                  @emitPercentualAjuste="setPercentualAjuste($event)"/>
    <div>
      <matricula-ajuste-mensalidade-data-table ref="dataTable"
                             :searchValues="searchValues"
                             :valorAjuste="valorAjuste"/>
    </div>
  </base-view>

</template>

<script>
import BaseView from "../../BaseView";
import MatriculaAjusteMensalidadeDataTable from "@/components/shared/datatables/MatriculaAjusteMensalidadeDataTable";
import InformaPercentualAjusteDialog from "../components/InformaPercentualAjusteDialog";
import {findAll as findAllHorario} from '../../../api/horarios';
import {findAll as findAllQuadra} from '../../../api/quadras';
import {findAll as findAllLocal} from '../../../api/locais';
import {findAll as findAllCurso} from '../../../api/cursos';
import {findAll as findAllCliente} from '../../../api/clientes';
import {efetivarAjusteMatricula} from '../../../api/matriculas';
import {ItemDeDominio} from "../../../api/itemsDominio";
import {mapActions, mapGetters} from "vuex";
import {ApiError} from "../../../api/core";

export default {
  name: "index",
  components: {BaseView, MatriculaAjusteMensalidadeDataTable, InformaPercentualAjusteDialog},
  data() {
    return {
      item: null,
      valorAjuste: 0,
      itensFiltrados: {},
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          name: 'Status Matrícula',
          column: 'stsMatricula',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_PADRAO
          })
        },        
        {
          name: 'Tipo Plano',
          column: 'tipoPlano',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_PLANO
          })
        },
        {
          name: 'Local',
          column: 'idLocal',
          type: 'autocomplete',
          optionsFactory: (search) => findAllLocal(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Curso',
          column: 'idCurso',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCurso(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Quadra',
          column: 'idQuadra',
          type: 'autocomplete',
          optionsFactory: (search) => findAllQuadra(1,{
            descricao: search
          })
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'autocomplete',
          optionsFactory: () => findAllHorario(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    informaPercentualAjuste() {
      this.$refs.informaPercentualAjusteDialog.open();
    },
    setPercentualAjuste(percentAjuste){
      this.valorAjuste = parseInt(percentAjuste)
    },
    updateSearch(search) {
        this.valorAjuste = 0;
        this.$refs.informaPercentualAjusteDialog.percentAjuste = 0;
        this.itensFiltrados = search;
        this.searchValues = search;
    },
    async efetivarAjuste(item){
      try {
        this.$refs.dataTable.loading = true
        await efetivarAjusteMatricula(this.valorAjuste, this.itensFiltrados);
        this.$toast.success(`Valores efetivados com sucesso.`, {
          timeout: 3000
        });
        this.valorAjuste = 0;
        this.$refs.informaPercentualAjusteDialog.percentAjuste = 0;
        this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível efetivar os novos valores.`, {
            timeout: 6000
          })
        }
      }
    },
    desejaMesmoEfetivarOsValores(){
      if (this.$refs.dataTable.elements.length <= 0) {
        this.$toast.error('Filtre a(s) matrícula(s) que deseja ajustar o valor!', {
           timeout: 6000
        })
        return;
      } 
      if (this.valorAjuste > 0){
        this.$swal({
              title: 'Deseja realmente efetivar os valores ajustados?',
              html: `Os valores serão reajustados em <strong>${this.valorAjuste}%</strong> e lembre-se que essa ação <font size='14px' color='red'>não tem retorno</font>.`,
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Não, vou analisar mais um pouco',
              confirmButtonText: 'Sim, quero efetivar'
            }).then((result) => {
              if (result.isConfirmed) {
                this.efetivarAjuste();
              }
            });
      } else {
        this.$toast.error('Para efetivar o ajuste é necessário informar o percentual e aplicá-lo!', {
           timeout: 6000
        })
      }
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>