var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
                  disableItemsPerPage: true,
                  itemsPerPageOptions: [_vm.itemsPerPage],
                  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                  var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.cursos",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.cursos.map(function (d) { return d.descricao_idCurso+' - '+d.qtdDias+' dia(s)'; }).join(', ')))])]}},{key:"item.valMatricula",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("R$ "+_vm._s(item.valMatricula))])]}},{key:"item.valDesconto",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("% "+_vm._s(item.valDesconto))])]}},{key:"item.valComDesconto",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("R$ "+_vm._s(_vm.valAjustadoComDesconto(item.valMatricula, _vm.valorAjuste, item.valDesconto)))])]}},{key:"item.valAjustadoMatricula",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v("R$ "+_vm._s(_vm.valAjustado(item.valMatricula, _vm.valorAjuste)))])]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
                  var items = ref.items;
                  var isSelected = ref.isSelected;
                  var select = ref.select;
return [_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"list-item"},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.item.nomeIdCliente)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.item.cursos.map(function (d) { return d.descricao_idCurso; }).join(', '))+" ")])],1),_c('v-list-item-action-text',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","input-value":"false","small":""}},[_vm._v("R$ "+_vm._s(_vm.item.valComDesconto))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }